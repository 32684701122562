import React, { useEffect } from 'react';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { ISignerData } from '../Sign/Esign/Action/EsignActions';
import { RouteComponentProps } from 'react-router';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IRecipientSignatureDetails } from '../../../model/ViewModel/ClientSignatureStatus';
import { handleRedirect } from '../Common/RedirectHelper';

export type SigningSuccessFlowProps = {
    primaryDetailsStore: IPublicData;
    signatureInfoStore: ISignerData;
    requestSignatureInfo: (clientId: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) => void;
    refreshToken: (clientId: string, callback?: () => void) => void;
}
    & RouteComponentProps<{}>

export const SigningSuccess: React.FunctionComponent<SigningSuccessFlowProps> = (props) => {

    useEffect(() => {
        if (props.signatureInfoStore.SignatureStatus.documentId == 0) {
            let param: any = props.match.params;
            let clientId = param.clientId;
            props.requestSignatureInfo(clientId, (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) =>
                handleRedirect(status, clientId, true, props.history));
        }
    }, []);



    return (<React.Fragment>
        <StepLayout>
            <Body title={""} bodyClassName={"sign-completed-body"}
                lg={10} md={12} sm={12} xs={12} height={90}
                automationTestId={"4903FAD2-DEA0-423F-82E5-22D85B6BD89B"}  >
                <br />
                <br />
                <div style={{ height: "40%", minHeight: "250px" }}>
                    <h5 data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24" style={{ fontWeight: 600 }}>
                        You have successfully signed the document(s)
                        </h5>
                    <br />
                    <br />
                    <br />
                    <p className="marB0 font15">  You'll receive a copy via email once everyone has signed.</p>
                </div>
            </Body>
        </StepLayout>
    </React.Fragment>);
}
