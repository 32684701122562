import { connect } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { SignerSignFlow } from './SignerSignFlow';
import { assignToDelegatee, declineSigning, requestSignatureInfo, requestDocumentControls, updateDelegatedSigner, delegationCancelled, sign } from '../Sign/Esign/Action/EsignActions';
import { IRecipientSignatureDetails } from '../../../model/ViewModel/ClientSignatureStatus';
import { ISignerControlDataModal } from '../../../model/Esign/ISignerControlDataModal';
import { withRouter } from 'react-router';
import { refreshToken } from '../Login/Action/LoginActions';
import { downloadAllDocument } from '../SignCompleted/Action/DownloadableDocumentActions';
import { DownloadStep } from '../../../model/Common/Enums';
import { ISignFinishedResponse } from 'src/model/ViewModel/ISignFinishedResponse';


const mapStateToProps = (state: ApplicationState) => ({
    taxingAuthorities: state.taxingAuthorities,
    signProcessInfo: state.signProcessInfo,
    primaryDetailsStore: state.primaryDetails,
    signatureInfoStore: state.signatureInfo,
    tokenInfo: state.tokenInfo
});

const mapDispatchToProps = (dispatch: any) => ({
    assignToDelegatee: (clientId: string, email: string, name: string, reason: string, successCallback?: (status: boolean) => void) =>
        dispatch(assignToDelegatee(clientId, email, name, reason, successCallback)),

    reAssignToDelegatee: (clientId: string, email: string, name: string, reason: string, successCallback?: (status: boolean) => void) =>
        dispatch(updateDelegatedSigner(clientId, email, name, reason, successCallback)),

    delegationCancelled: (clientId: string, successCallback?: (status: boolean) => void) => dispatch(delegationCancelled(clientId, successCallback)),

    requestSignatureInfo: (clientId: string, successCallback: (status: IRecipientSignatureDetails, DataService: any, ignoreStatus: boolean) => void) =>
        dispatch(requestSignatureInfo(clientId, successCallback)),

    declineSigning: (clientid: string, remarks: string, successCallback?: (status: boolean) => void) =>
        dispatch(declineSigning(clientid, remarks, successCallback)),

    sign: (clientId: string, signatureData: ISignerControlDataModal, successCallback?: (status: ISignFinishedResponse) => void) =>
        dispatch(sign(clientId, signatureData, successCallback)),

    requestDocumentControls: (clientId: string, isControlsDisabled: boolean, skipTracking?: boolean, successCallback?: () => void) =>
        dispatch(requestDocumentControls(clientId, isControlsDisabled, skipTracking, successCallback)),

    refreshToken: (clientId: string, successCallback?: () => void) => dispatch(refreshToken(clientId, successCallback)),

    downloadAllDocument: (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) =>
        dispatch(downloadAllDocument(clientId, fileName, isSigningCompleted, downloadStep)),
});

export const SignerSignFlowContainer = withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignerSignFlow));
