import { container } from "../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../core/startup/inversify/types";
import { FileUtilities } from "../../../../core/utilities/File/FileUtilities";
import { ILoader } from "../../../../core/utilities/ui/Loader";
import { DownloadStep } from '../../../../model/Common/Enums';
import { axiosFetch } from "../../../../core/services/dataAccess/DataService.Axios";
import { actionTypes } from "../../../../common/constants/actionTypes";
import { IFileControl } from "src/model/ViewModel/DocumentInfo";

const fileUtilities = container.get<FileUtilities>(TYPES.IFileUtilities);
const loader = container.get<ILoader>(TYPES.ILoader);

export const downloadAllDocument = (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep, isMigratedSigningIdForAudit: boolean = false) => (dispatch: any) => {

    //const clientId: string = SignerHelper.getClientId();
    loader.show();
    var config = { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json;utf-8' }, withCredentials: true };
    axiosFetch(clientId).getWithConfig('api/Download/DownloadAllSignedDocumentAsync?'
        + "fileName=" + fileName + "&isSigningCompleted=" + isSigningCompleted + "&downloadStep=" + downloadStep + "&isMigratedSigningId=" + isMigratedSigningIdForAudit, config)
        .then(function (response: any) {
            fileUtilities.show(response.data, fileName);
            loader.hide();
        }).catch(function (error: any) {
            console.log(error);
            loader.hide();
        });
};

export const requestDocuments = (clientId: string,) => (dispatch: any) => {

    //const clientId: string = SignerHelper.getClientId();
    loader.show();

    axiosFetch(clientId).get('api/Signing/GetAllDocumentsAsync')
        .then(function (response: any) {
            const { data } = response;
            dispatch({
                type: actionTypes.RECEIVE_DOCUMENTS, documents: data
            });
            loader.hide()
        }).catch(function (error: any) {
            console.log(error);
            loader.hide();
        });
};


export interface IDownloadData {
    documents: IFileControl[],
}

const unloadedState: IDownloadData = {
    documents: [
        {
            fileId: "",
            documentId: 0,
            expiresOn: new Date(),
            fileName: "",
            documentStatusName: "",
            documentName : ""
        }
    ]
} as IDownloadData;


export const downloadsReducer = (state: IDownloadData = unloadedState, action: any) => {
    switch (action.type) {

        case actionTypes.RECEIVE_DOCUMENTS: {
            return {
                documents: action.documents,
            } as IDownloadData;
        }
    }
    return state || unloadedState;
}