import * as React from 'react';
import CheckboxTree from 'react-checkbox-tree';
// import { DocumentType } from './FileListBase';
// import { PdfViewManager } from '../../Common/PdfView/PdfViewBase';
import { IDocument } from 'src/model/Esign/Document';
import { PdfViewManager } from './PdfViewBase';

export interface FileListProps {
    selectedDocumentId: string;
    documents: IDocument[];
    onFileClick(document: IDocument): void;
}

export interface FileListState {
    expanded: string[],
    nodes: any[]
}

export class FileList extends React.Component<FileListProps, FileListState> {


    constructor(props: any) {
        super(props);

        this.state = {
            expanded: [],
            nodes: []
        };

    }

    componentDidUpdate() {
        this.initialiseFileList();
    }

    componentDidMount(): void {
        this.initialiseFileList();
    }

    shouldComponentUpdate(nextProps: FileListProps, nextState: FileListState) {

        if (nextProps.documents.length > 0 && (this.props.documents.length !== nextProps.documents.length
            || this.state.nodes.length !== nextState.nodes.length || this.state.expanded.length !== nextState.expanded.length)
            || this.props.selectedDocumentId !== nextProps.selectedDocumentId) {
            return true;
        }

        return false;
    }

    initialiseFileList= () => {
        PdfViewManager.highlightSelectedFile(this.props.selectedDocumentId);
        if (this.state.nodes.length <= 0) {
            this.setState({
                expanded: [],
                nodes: this.createTreeNodes()
            });
        }
    }

    onExpand = (expanded: any) => {
        this.setState({ expanded });
    }

    static getNodeLabel = (lable: string) => {
		return <span title={lable}> {lable} </span>;
	}

    private createTreeNodes = (): any[] => {

        let nodes: any[] = [];

        for(var i=0; i<= this.props.documents.length-1; i++) {
            let tmpNode: any = {              
                disabled: false,
                value: this.props.documents[i].fileId,
                label: this.props.documents[i].fileName, //.length < FileListStrings.FILE_NAME_TEXT_LIMIT ? tmpDocument.name : tmpDocument.name.slice(0, FileListStrings.FILE_NAME_TEXT_LIMIT) + "...",
                showcheckbox: true,
                icon: this.getPdfIcon(),
                title: this.props.documents[i].fileName,
                className: "file-node-" + this.props.documents[i].fileId 
            };
            nodes.push(tmpNode);
        }

        return nodes;
    }

    private getPdfIcon() {
        return <i className="fa fa-file-pdf-o" />
    }

    private handleFileClick = (node: any) => {

        if (node.children == undefined) {

            const tmpDocument = this.props.documents.find(x => x.fileId == node.value && x.disabled == false);
            tmpDocument && this.props.onFileClick(tmpDocument);

        }
    }

    public render() {

        const { nodes, expanded } = this.state;

        return (<div className="file-list-tree-view-container">

            <CheckboxTree
                showExpandAll={false}
                expanded={expanded}
                iconsClass="fa5"
                nodes={nodes}
                expandOnClick
                onClick={this.handleFileClick}
                onExpand={this.onExpand}
            />
        </div>);

    }
}
