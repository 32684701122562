import { IControl } from "./IControls";

export interface IDocument {

    fileId: string;
    fileName: string;
    fileLink: string;
    disabled: boolean;
    pages: IPage[];
    documentName:string;
}

export class Document implements IDocument {

    fileId: string;
    fileName: string;
    fileLink: string;
    disabled: boolean;
    pages: IPage[];
    documentName:string;

    constructor(fileId: string, fileName: string, fileLink: string, disabled: boolean, pages: IPage[],documentName :string) {

        this.fileId = fileId;
        this.fileName = fileName;
        this.fileLink = fileLink;
        this.disabled = disabled;
        this.pages = pages;
        this.documentName = documentName;
    }


    public static create(fileId: string, fileName: string, fileLink: string, disabled: boolean, pages: IPage[], documentName:string): IDocument {
        return new Document(fileId, fileName, fileLink, disabled, pages, documentName);
    }

    public static createNullObject(): IDocument {
        return new Document("", "", "", false, [],"");
    }
}


export interface IPage {
    page: number;
    controls: IControl[];
}

export class Page implements IPage {
    page: number;
    controls: IControl[];


    constructor(page: number, controls: IControl[]) {

        this.page = page;
        this.controls = controls;

    }

    public static create(page: number, controls: IControl[]): IPage {
        return new Page(page, controls);
    }

}