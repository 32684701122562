import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CustomInput } from '../../common/TextBox/CustomInput';
import { CustomTextArea } from '../../common/TextBox/CustomTextArea';
import { DelegateHelp } from '../LandingPage/DelegateHelp';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { IUtilities } from '../../../core/utilities/Utilities';
import { isStringNullOrEmpty } from 'src/components/common/Helper/HelperFunctions';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const utilities = container.get<IUtilities>(TYPES.IUtilities);

export interface AssignToDelegateeProps {
    showState: boolean;
    onHide: () => void;
    onHideDelegateHelpClick(): void;
    showDelegateHelpPopUp: boolean;
    onShowDelegateHelpPopUp(): void;
    onDelegateSaveClick: (signerName: string, emailAddress: string, changeReason: string) => void;
    onDelegateReassignClick: (signerName: string, emailAddress: string, changeReason: string) => void;
    onDelegationCancelled: () => void;

    AssignToDelegateeSigner: boolean;
    DelegationAssigned?: string;

    emailAddress: string;
    signerName: string;
    changeReason: string;
}

export const AssignToDelegatee: React.FunctionComponent<AssignToDelegateeProps> = (props) => {

    const [signerName, setSignerName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [changeReason, setChangeReason] = useState('');

    const initializeState = () => {
        setSignerName('');
        setEmailAddress('');
        setChangeReason('');
    }

    const onHide = () => {
        props.onHide();
    }

    const onCancel = () => {
        initializeState();
        props.onHide();
    }

    const handleSignerNameChange = (event: any) => {
        setSignerName(event.target.value);
    }

    const handleEmailAddressChange = (event: any) => {
        setEmailAddress(event.target.value);
    }

    const handleChangeReasonChange = (event: any) => {
        setChangeReason(event.target.value);
    }

    const onDelegateSaveClick = () => {
        var isValidate = validateDetails();
        if (isValidate) {
            props.onDelegateSaveClick(signerName ?? "", emailAddress ?? "", changeReason ?? "");
        }
    }

    const onDelegateReassignClick = () => {
        var isValidate = validateDetails();
        if (isValidate) {
            props.onDelegateReassignClick(signerName ?? "", emailAddress ?? "", changeReason ?? "");
        }
    }

    const validateDetails = () => {
        var result = true;
        if (emailAddress?.trim() == "") {
            snackbar.show("Please enter email address.");
            return result = false;
        }
        else if (!utilities.isValidEmailAddress(emailAddress.trim()) || emailAddress.trim() == "") {
            snackbar.show("Please enter valid  email address.");
            return result = false;
        }
        else if (signerName.trim() == '') {
            snackbar.show("Please enter signer name.");
            return result = false;
        }
        else if (changeReason.length > 200) {
            snackbar.show("Maximum 200 character allowed for declining reason.");
            return result = false;
        }
        return result;
    }

    return (
        <div>
            <Modal show={props.showState} className="assignToDelegate" onHide={onHide}
                data-test-auto="DD0254E3-0679-43D4-9F0C-A4B8858F2584" centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: '20px' }}>
                        <span className="fa ddl-icon"></span>
                        {props.AssignToDelegateeSigner == true || !isStringNullOrEmpty(props.DelegationAssigned) ?
                            <div>
                                <div style={{ textAlign: "center" }}> <strong>Re-Delegate to someone else</strong></div>
                                <div>
                                    <span style={{ fontSize: '12px', lineHeight: '15px', paddingTop: '10px' }} className="fa fa-info-circle">Upon Re-Delegating, the initial email link sent to the previous signer will be invalid.</span>
                                </div> </div> : <strong> Delegate Signature</strong>
                        }
                        <span className="fa fa-question-circle-o" style={{ fontSize: '25px' }} onClick={() => { props.onShowDelegateHelpPopUp() }}> </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-12" style={{ display: 'inline' }}>
                            <label>New Signer's Name</label><span className="fa fa-asterisk compulsory-star1"></span>
                            <div>
                                <input id="delegateName" className="form-control form-control-green form-rounded" autoComplete={"off"}
                                    data-test-auto="1B8DD209-57F7-45C0-BCBD-531B2BEAA341" placeholder="First and Last Name"
                                    value={signerName}
                                    type="text" onChange={handleSignerNameChange} />
                            </div>
                            <br />
                            <label>Email Address For The New Signer</label><span className="fa fa-asterisk compulsory-star1"></span>
                            <div>
                                <input id="delegateEmail" className="form-control form-control-green form-rounded" autoComplete={"off"}
                                    data-test-auto="F7AB88EF-BD05-415D-8B13-AE38C3D16460" placeholder="Email Address"
                                    value={emailAddress}
                                    type="text" onChange={handleEmailAddressChange} />
                            </div>
                            <br />
                            <label>Reason for changing signing responsibility</label>
                            <div>
                                <textarea id="delegateReason" className="form-control form-control-green form-rounded textarea-size"
                                    data-test-auto="2CE9E22A-1B12-4F7F-8DE8-8E7AD19003A0" placeholder="Please enter a reason (200 characters or less)"
                                    value={changeReason}
                                    onChange={handleChangeReasonChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {
                    <Modal.Footer>
                        <Button
                            id="btnHideDelegatePopUp"
                            onClick={onCancel}
                            data-test-auto="3697DAE9-26F9-4DA2-87BD-DB9260429148"
                            className="btn btn-white btn-default rounded bootbox-cancel float-left"
                            variant={"light"}> Cancel
                            </Button>
                        {props.AssignToDelegateeSigner == true || !isStringNullOrEmpty(props.DelegationAssigned) ?
                            <Button
                                onClick={onDelegateReassignClick}
                                data-test-auto="1E87310B-F59F-45AB-AC16-AA6027DD6C13"
                                className="btn btn-primary rounded bootbox-accept float-right"
                                variant={"primary"}> Send
                                </Button>
                            :
                            <Button
                                onClick={onDelegateSaveClick}
                                data-test-auto="DE882478-8F9C-4530-B9C0-3B414D8261A9"
                                className="btn btn-primary rounded bootbox-accept float-right"
                                variant={"primary"}> Send
                                </Button>
                        }
                    </Modal.Footer>}
            </Modal>

            <DelegateHelp
                key="value"
                onHide={props.onHideDelegateHelpClick}
                showState={props.showDelegateHelpPopUp}
            />

        </div >
    );
}