import { ApplicationState } from '../../../../reducers';
import { connect } from 'react-redux';
import { Cancelled } from './Cancelled';
import { IRecipientSignatureDetails } from '../../../../model/ViewModel/ClientSignatureStatus';
import { requestSignatureInfo } from '../../Sign/Esign/Action/EsignActions';
import { refreshToken } from '../../Login/Action/LoginActions';

const mapStateToProps = (state: ApplicationState) => ({
    primaryDetailsStore: state.primaryDetails,
    signatureInfoStore: state.signatureInfo
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSignatureInfo: 
        (
            clientId: string, 
            successCallback: 
            (
                status: IRecipientSignatureDetails, 
                DataService: any, 
                ignoreStatus: boolean
            ) => void
        ) =>
        dispatch(
            requestSignatureInfo(clientId, successCallback)
        ),
        refreshToken: (clientId: string, successCallback?: () => void) => dispatch(refreshToken(clientId, successCallback)),
    }
}

export const CancelledContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Cancelled);
