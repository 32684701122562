export enum actionTypes {

    REQUEST_PRIMARY_INFO = 1,
    RESPONSE_PRIMARY_INFO = 2,
    RECEIVE_CLIENT_SIGNATURE_STATUS = 3,
    RECEIVE_ASSIGN_TO_DELEGATEE_STATUS = 4,
    RECEIVE_DOCUMENT_CONTROLS = 5,
    DECLINE_DELEGATION = 6,
    RECEIVE_USER_TOKEN = 7,
    RECEIVE_PUBLIC_USER_ACCESSTOKEN = 8,
    RECEIVE_DOCUMENTS = 9,
    CA_REQUEST = 10,
    CA_RESPONSE = 11,
    CA_FAILURE = 12,
    RECEIVE_STATES = 13,
    INITIATE_REQUEST,
    COMPLETE_RESPONSE
};
