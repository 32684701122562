import React, { useEffect, useState } from 'react';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IRecipientSignatureDetails } from '../../../model/ViewModel/ClientSignatureStatus';
import SignerHelper from '../signflow/Helper/SignerHelper';
import { RouteComponentProps } from 'react-router';
import { handleRedirect } from '../Common/RedirectHelper';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export type OtpProps = {
    primaryDetailsStore: IPublicData;
    requestSignatureInfo: (clientId: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) => void;
    requestOTP: (clientId: string, successCallback?: () => void) => void;
    validateOTP: (clientId: string, otp: string, successCallback: any) => void;
}
    & RouteComponentProps<{}>

export const Otp: React.FunctionComponent<OtpProps> = (props) => {

    const [otp, setOtp] = useState('');
    const [disabledContinueButton, setDisabledContinueButton] = useState(true);

    useEffect(() => {
        validateOTP();
    }, [otp])


    const handleOtpChange = (evt: any) => {
        let value = evt.target.value;
        let numbers = value.replace(/[^0-9]/g, "");
        evt.target.value = numbers;
        value = evt.target.value;
        setOtp(value);
    }

    const handleSendOtpBtnClick = () => {
        let param: any = props.match.params;
        props.requestOTP(param.Id);
    }

    const handleValidateOtpBtnClick = () => {
        let param: any = props.match.params;
        if (otp && parseInt(otp)) {
            if (otp.toString().length != 8) {
                snackbar.show("Enter 8 digit one-time access code");
            }
            else {
                props.validateOTP(param.Id, otp, checkClientSignatureStatus);
            }
        }
        else {
            snackbar.show("Enter a valid one-time access code");
        }
    }

    const validateOTP = () => {

        otp
            && Number(otp)
            && otp.toString().length == 8 ?
            setDisabledContinueButton(false) :
            setDisabledContinueButton(true)
    }

    const checkClientSignatureStatus = (clientId: any) => {
        //let param: any = this.props.match.params;
        props.requestSignatureInfo(clientId, (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) =>
            handleRedirect(status, clientId, ignoreStatus, props.history));
    }

    return (
        <div className="container container-height">
            <div className="form-horizontal emailAuthDiv form-horizontal-height">
                <div className="">
                    <h4>Authentication</h4>
                </div>
                <div className="">
                    <label className="form-label form-label-bold">Access Code Required</label>
                </div>
                <div className="form-group">
                    <label className="form-label fontMed">Please select “Request Access Code” and we will send you a one-time expiring access code to your email.</label>
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-secondary" onClick={handleSendOtpBtnClick}
                        id="linkResendOTP">Request Access code</button>
                </div>
                <div className="">
                    <label className="form-label fontMed">Enter access code here:</label>
                </div>
                <div className="form-group wid170">
                    <input id="OTP" className="form-control form-control-green form-rounded"
                        autoComplete={"off"} name="txtOTP"
                        placeholder="Enter Access Code" type="text" pattern="[0-9]*" onChange={handleOtpChange} />
                </div>
                <div className="">
                    <small className="form-label fontItalics">(This code will expire in 20 minutes)</small>
                </div>
            </div>
            <div>
                <div className="form-group emailContinueBtn">
                    <a className="rd-nav-link">
                        <button type="button" disabled={disabledContinueButton}
                            className="btn btn-primary btn-primary-extended btn-login"
                            onClick={handleValidateOtpBtnClick} id="btnContinue">
                            Continue
                                </button>
                    </a>
                </div>
            </div>
        </div>
    )
}