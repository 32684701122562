import React, { useEffect } from 'react';
import { ISignerData } from '../Sign/Esign/Action/EsignActions';
import { RouteComponentProps } from 'react-router';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IRecipientSignatureDetails } from '../../../model/ViewModel/ClientSignatureStatus';
import { handleRedirect } from '../Common/RedirectHelper';


export type StampingNotCompleteProps = {
    primaryDetailsStore: IPublicData;
    signatureInfoStore: ISignerData;
    requestSignatureInfo: (clientId: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) => void;
    refreshToken: (clientId: string, callback?: () => void) => void;
}
    & RouteComponentProps<{}>


export const StampingNotComplete: React.FunctionComponent<StampingNotCompleteProps> = (props) => {

    useEffect(() => {
        if (props.signatureInfoStore.SignatureStatus.documentId == 0) {
            let param: any = props.match.params;
            let clientId = param.clientId;
            props.requestSignatureInfo(clientId, (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) =>
                handleRedirect(status, clientId, true, props.history));
        }
    }, []);

    return (
        <div>
            <div id="content-wrapper" className="col-xs-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page">
                    <div className="greeting text-center">
                        We are preparing your signed document. To download a copy, return to your summary page shortly.
                    </div>
                </div>
            </div>
        </div>
    )
}