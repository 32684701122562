import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IRecipientSignatureDetails } from '../../../model/ViewModel/ClientSignatureStatus';
import SignerHelper from '../../Pages/signflow/Helper/SignerHelper';
import { handleRedirect } from '../Common/RedirectHelper';
import { ITokenData } from '../Login/Action/LoginActions';
import { IPublicUserTokenData } from '../signflow/Action/SignerSignFlowActions';
import { hideFinishButton } from '../../common/Helper/HelperFunctions';

export type PublicLoginProps =
    {
        publicData: IPublicData,
        tokenInfo: ITokenData,
        primaryTokenInfo: IPublicUserTokenData,
        requestSignatureInfo: (clientId: string, callback: (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) => void) => void;
        getTokenForAnonymous: (id: string, callback: (clientId: any) => void) => void;
    }
    & RouteComponentProps<{}>


export const PublicLogin: React.FunctionComponent<PublicLoginProps> = (props) => {

    useEffect(() => {
        let param: any = props.match.params;
        if (!props.primaryTokenInfo.token) {
            props.getTokenForAnonymous(param.Id,
                (clientId: string) => {
                    SignerHelper.setClientId(clientId);
                    props.requestSignatureInfo(clientId, (status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean) =>
                        handleRedirect(status, clientId, ignoreStatus, props.history));
                });
        }
        window.onpopstate = (() => {
            hideFinishButton(); //Updation of Remove Finish button is issue in Awesome-pdf-viewer this function is just for hiding the Finsh button for the bug-80417
            history.go(1)
        });
    }, []);

    return (
        <div></div>
    )
}