import { ApplicationState } from '../../../../reducers';
import { connect } from 'react-redux';
import { Expired } from './Expired';
import { IRecipientSignatureDetails } from '../../../../model/ViewModel/ClientSignatureStatus';
import { requestSignatureInfo } from '../../Sign/Esign/Action/EsignActions';

const mapStateToProps = (state: ApplicationState) => ({
    primaryDetailsStore: state.primaryDetails,
    signatureInfoStore: state.signatureInfo
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestSignatureInfo: (clientId: string, successCallback: (status: IRecipientSignatureDetails, DataService: any, ignoreStatus: boolean) => void) =>
            dispatch(requestSignatureInfo(clientId, successCallback)),
    }
}

export const ExpiredContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Expired);
