import { PathConstants } from "../../../common/constants/Constants";
import { History } from 'history';
import { ILocalStore } from "../../../core/utilities/LocalStore";
import { container } from "../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../core/startup/inversify/types";
import { DocumentStatus, IRecipientSignatureDetails, StampingStatus } from "src/model/ViewModel/ClientSignatureStatus";

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export function handleRedirect(status: IRecipientSignatureDetails, clientId: any, ignoreStatus: boolean, history: History) {

    if (!ignoreStatus && status == undefined
        || (Object.keys(status).length === 0 && status.constructor === Object)
        || (status && (status.documentStatus === DocumentStatus.Deleted ||
            status.documentStatus === DocumentStatus.SignatureStampingFailed))
    ) {
       localStore.remove('loggedIn');
        history.push(`${PathConstants.Invalid}`);
    }
    else if (status) {
        if (status.documentStatus === DocumentStatus.Cancelled) {
            history.push(`${PathConstants.Cancelled}${clientId}`);
        }
        else if (status.documentStatus === DocumentStatus.Declined) {
            history.push(`${PathConstants.Declined}${clientId}`);
        }
        else if (status.isExpired) {
            history.push(`${PathConstants.Expired}${clientId}`);
        }
        else if (status.isAllSigned || status.stampingStatus === StampingStatus.Completed) {
            history.push(`${PathConstants.Download}${clientId}`);
        }
        else {
            if (status.stampingStatus === StampingStatus.Processing ||
                status.stampingStatus === StampingStatus.Failed) {
                history.push(`${PathConstants.SigningProgress}${clientId}`);
            }
            else
                history.push(`${PathConstants.Esign}${clientId}`);
        }
    }
}